import { inputTypes } from '../constants'
import {
  sanitizeNumber,
  sanitizeString,
  sanitizeArrayItems
} from '../sanitizations'
import { createOption } from './helpers'
import {
  baseValidationFields,
  genIsRequiredField,
  genMinField,
  genPickOneField,
  genRequiredMinField,
  genPickAtleastOneField,
  genLink,
  genRequiredMaxLengthField
} from '../validation'
import { addHttpToLink } from '../../components/FormValidator'
import { MUNICIPALITIES } from '../../state/constants'

const conferenceRoomInputFields = [
  {
    field: 'images',
    displayName: 'Kuvat kohteesta (kuvan täytyy olla vaakasuunnassa)',
    input: {
      isRequired: true,
      maxImages: 12
    },
    validation: baseValidationFields['images']
  },
  {
    field: 'overview',
    displayName: 'Kohteen kuvaus',
    input: {
      isRequired: true,
      maxLength: 1300,
      sanitize: sanitizeString
    },
    validation: genRequiredMaxLengthField(1300, 'Kohteen kuvaus')
  },
  {
    field: 'type',
    displayName: 'Tyyppi',
    input: {
      isRequired: true,
      inputType: inputTypes.DROPDOWN,
      options: [
        createOption('Auditorio'),
        createOption('Seminaarisali'),
        createOption('Kabinetti'),
        createOption('Kerhohuone'),
        createOption('Sauna'),
        createOption('Kokoustila'),
        createOption('Neuvottelutila'),
        createOption('Ryhmätyötila / koulutustila'),
        createOption('Juhlatila'),
        createOption('Muu tila')
      ],
      sanitize: sanitizeString
    },
    validation: genPickOneField('Tyyppi')
  },
  {
    field: 'address',
    displayName: 'Osoite',
    input: {
      isRequired: true,
      maxLength: 512,
      inputType: inputTypes.TEXT,
      sanitize: sanitizeString
    },
    validation: genIsRequiredField('Osoite')
  },
  {
    field: 'zipCode',
    displayName: 'Postinumero',
    input: {
      isRequired: true,
      inputType: inputTypes.TEXT,
      sanitize: sanitizeString
    },
    validation: baseValidationFields['zipCode']
  },
  {
    field: 'municipality',
    displayName: 'Kaupunki / kunta',
    input: {
      isRequired: true,
      inputType: inputTypes.DROPDOWN,
      options: MUNICIPALITIES.map(name => createOption(name)),
      sanitize: sanitizeString
    },
    validation: baseValidationFields['municipality']
  },
  {
    field: 'district',
    displayName: 'Kaupunginosa',
    input: {
      isRequired: false,
      maxLength: 255,
      inputType: inputTypes.TEXT,
      sanitize: sanitizeString
    }
  },
  {
    field: 'areaM2',
    displayName: 'Pinta-ala',
    input: {
      isRequired: false,
      min: 0,
      inputType: inputTypes.NUMBER,
      unit: 'm2',
      sanitize: sanitizeNumber
    },
    validation: genMinField(0, 'Pinta-alan', ' m2')
  },
  {
    field: 'name',
    displayName: 'Nimi',
    input: {
      isRequired: true,
      maxLength: 255,
      inputType: inputTypes.TEXT,
      sanitize: sanitizeString
    },
    validation: genIsRequiredField('Nimi')
  },
  {
    field: 'capacity',
    displayName: 'Kapasiteetti',
    input: {
      isRequired: true,
      min: 2,
      inputType: inputTypes.NUMBER,
      unit: 'hlö',
      sanitize: sanitizeNumber
    },
    validation: genRequiredMinField(2, 'Kapasiteetti', 'Kapasiteetin', ' hlöä')
  },
  {
    field: 'suitability',
    displayName: 'Soveltuvuus',
    input: {
      isRequired: true,
      inputType: inputTypes.CHECKBOX_LIST,
      options: [
        createOption('Esteetön tila'),
        createOption('Juhlien järjestäminen'),
        createOption('Kokous'),
        createOption('Konsertti'),
        createOption('Koulutustilaisuus'),
        createOption('Messut'),
        createOption('Saunailta'),
        createOption('Suurtapahtuma'),
        createOption('Yrityksen edustustilaisuus')
      ],
      sanitize: val => sanitizeArrayItems(sanitizeString, val)
    },
    validation: genPickAtleastOneField('Soveltuvuus')
  },
  {
    field: 'services',
    displayName: 'Oheispalvelut',
    input: {
      isRequired: true,
      inputType: inputTypes.CHECKBOX_LIST,
      options: [
        createOption('Ravintolapalvelut / catering'),
        createOption('Pysäköinti'),
        createOption('IT-tuki'),
        createOption('Naulakkopalvelu'),
        createOption('Majoituspalvelut'),
        createOption('Kokousavustaja'),
        createOption('Sauna'),
        createOption('Uima-allas'),
        createOption('Elävää musiikkia'),
        createOption('Ohjelmapalvelut'),
        createOption('Simultaanitulkkaus'),
        createOption('Spa, hyvinvointi, kauneushoito'),
        createOption('Muu')
      ],
      sanitize: val => sanitizeArrayItems(sanitizeString, val)
    },
    validation: genPickAtleastOneField('Oheispalvelu')
  },
  {
    field: 'equipment',
    displayName: 'Varustus',
    input: {
      isRequired: true,
      inputType: inputTypes.CHECKBOX_LIST,
      options: [
        createOption('Esitystekniikka'),
        createOption('Piano'),
        createOption('Äänentoisto'),
        createOption('Puhujan pönttö'),
        createOption('Esiintymislava'),
        createOption('Lippujalka'),
        createOption('Fläppitaulu'),
        createOption('Valkotaulu'),
        createOption('Tietokone'),
        createOption('Pelilaitteet'),
        createOption('Karaokelaitteet'),
        createOption('Mikrofoni'),
        createOption('Videoneuvottelulaitteet'),
        createOption('Wifi'),
        createOption('Induktiosilmukka'),
        createOption('Tulkkauslaitteisto'),
        createOption('Muu')
      ],
      sanitize: val => sanitizeArrayItems(sanitizeString, val)
    },
    validation: genPickAtleastOneField('Varustus')
  },
  {
    field: 'additionalInformation',
    displayName: 'Muuta',
    input: {
      isRequired: false,
      maxLength: 1000,
      inputType: inputTypes.TEXTAREA,
      sanitize: sanitizeString
    }
  },
  {
    field: 'attachment',
    displayName: 'Liite',
    input: {
      isRequired: false,
      inputType: inputTypes.ATTACHMENT,
      sanitize: sanitizeString
    }
  },
  {
    field: 'link',
    displayName: 'Linkki',
    input: {
      isRequired: false,
      maxLength: 255,
      inputType: inputTypes.TEXT,
      sanitize: sanitizeString,
      transform: addHttpToLink
    },
    validation: genLink()
  },
  {
    field: 'price',
    displayName: 'Hinta (alkaen)',
    input: {
      isRequired: true,
      inputType: inputTypes.TEXT,
      sanitize: sanitizeString
    },
    validation: genIsRequiredField('Hinta')
  },
  {
    field: 'includedInPrice',
    displayName: 'Kuuluu hintaan',
    input: {
      isRequired: false,
      maxLength: 255,
      inputType: inputTypes.TEXT,
      sanitize: sanitizeString
    }
  },
  {
    field: 'otherExpenses',
    displayName: 'Muut kustannukset',
    input: {
      isRequired: false,
      maxLength: 512,
      inputType: inputTypes.TEXTAREA,
      sanitize: sanitizeString
    }
  },
  {
    field: 'parking',
    displayName: 'Pysäköinti',
    input: {
      isRequired: false,
      maxLength: 255,
      inputType: inputTypes.TEXT,
      sanitize: sanitizeString
    }
  },
  {
    field: 'access',
    displayName: 'Kulkuyhteydet',
    input: {
      isRequired: false,
      maxLength: 1000,
      inputType: inputTypes.TEXTAREA,
      sanitize: sanitizeString
    }
  }
]

export default conferenceRoomInputFields
