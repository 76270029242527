import { inputTypes } from '../constants'
import {
  sanitizeNumber,
  sanitizeBoolean,
  sanitizeString
} from '../sanitizations'
import { createOption } from './helpers'
import {
  baseValidationFields,
  genIsRequiredField,
  genPickOneField,
  genMinField,
  genRequiredMinField,
  genLink,
  genRequiredMaxLengthField,
  genRequiredMinIntegerField,
  genMinIntegerField
} from '../validation'
import { addHttpToLink } from '../../components/FormValidator'
import { MUNICIPALITIES } from '../../state/constants'

const forSaleInputFields = [
  {
    field: 'images',
    displayName: 'Kuvat kohteesta (kuvan täytyy olla vaakasuunnassa)',
    input: {
      isRequired: true,
      maxImages: 12
    },
    validation: baseValidationFields['images']
  },
  {
    field: 'overview',
    displayName: 'Kohteen kuvaus',
    input: {
      isRequired: true,
      maxLength: 1300,
      sanitize: sanitizeString
    },
    validation: genRequiredMaxLengthField(1300, 'Kohteen kuvaus')
  },
  {
    field: 'type',
    displayName: 'Toimitilatyyppi',
    input: {
      isRequired: true,
      inputType: inputTypes.DROPDOWN,
      options: [
        createOption('Liiketila'),
        createOption('Toimistotila'),
        createOption('Teollisuustila'),
        createOption('Varasto')
      ],
      sanitize: sanitizeString
    },
    validation: genPickOneField('Toimitilatyyppi')
  },
  {
    field: 'address',
    displayName: 'Osoite',
    input: {
      isRequired: true,
      maxLength: 512,
      inputType: inputTypes.TEXT,
      sanitize: sanitizeString
    },
    validation: genIsRequiredField('Osoite')
  },
  {
    field: 'zipCode',
    displayName: 'Postinumero',
    input: {
      isRequired: true,
      inputType: inputTypes.TEXT,
      sanitize: sanitizeString
    },
    validation: baseValidationFields['zipCode']
  },
  {
    field: 'municipality',
    displayName: 'Kaupunki / kunta',
    input: {
      isRequired: true,
      inputType: inputTypes.DROPDOWN,
      options: MUNICIPALITIES.map(name => createOption(name)),
      sanitize: sanitizeString
    },
    validation: baseValidationFields['municipality']
  },
  {
    field: 'district',
    displayName: 'Kaupunginosa',
    input: {
      isRequired: false,
      maxLength: 255,
      inputType: inputTypes.TEXT,
      sanitize: sanitizeString
    }
  },
  {
    field: 'condition',
    displayName: 'Kunto',
    input: {
      isRequired: true,
      inputType: inputTypes.DROPDOWN,
      options: [
        createOption('Hyvä'),
        createOption('Kohtalainen'),
        createOption('Huono')
      ],
      sanitize: sanitizeString
    },
    validation: genPickOneField('Kunto')
  },
  {
    field: 'areaM2',
    displayName: 'Pinta-ala',
    input: {
      isRequired: true,
      min: 0,
      inputType: inputTypes.NUMBER,
      unit: 'm2',
      sanitize: sanitizeNumber
    },
    validation: genRequiredMinIntegerField(0, 'Pinta-ala', 'Pinta-alan', ' m2')
  },
  {
    field: 'propertyAreaM2',
    displayName: 'Tontin pinta-ala',
    input: {
      isRequired: false,
      min: 0,
      inputType: inputTypes.NUMBER,
      unit: 'm2',
      sanitize: sanitizeNumber
    },
    validation: genMinIntegerField(0, 'Pinta-alan', ' m2')
  },
  {
    field: 'condominium',
    displayName: 'Taloyhtiön nimi',
    input: {
      isRequired: false,
      inputType: inputTypes.TEXT,
      sanitize: sanitizeString
    }
  },
  {
    field: 'numberOfRooms',
    displayName: 'Huoneiden lukumäärä',
    input: {
      isRequired: false,
      min: 1,
      inputType: inputTypes.NUMBER,
      sanitize: sanitizeNumber
    },
    validation: genMinField(1, 'Huoneita')
  },
  {
    field: 'floor',
    displayName: 'Kerros',
    input: {
      isRequired: false,
      inputType: inputTypes.NUMBER,
      sanitize: sanitizeNumber
    }
  },
  {
    field: 'hasElevator',
    displayName: 'Hissi',
    input: {
      isRequired: false,
      inputType: inputTypes.DROPDOWN,
      options: [createOption('Kyllä', true), createOption('Ei', false)],
      sanitize: sanitizeBoolean
    }
  },
  {
    field: 'additionalInformationOnRelease',
    displayName: 'Lisätietoa vapautumisesta',
    input: {
      isRequired: false,
      maxLength: 255,
      inputType: inputTypes.TEXTAREA,
      sanitize: sanitizeString
    }
  },
  {
    field: 'additionalInformation',
    displayName: 'Muuta',
    input: {
      isRequired: false,
      maxLength: 1000,
      inputType: inputTypes.TEXTAREA,
      sanitize: sanitizeString
    }
  },
  {
    field: 'attachment',
    displayName: 'Liite',
    input: {
      isRequired: false,
      inputType: inputTypes.ATTACHMENT,
      sanitize: sanitizeString
    }
  },
  {
    field: 'link',
    displayName: 'Linkki',
    input: {
      isRequired: false,
      maxLength: 255,
      inputType: inputTypes.TEXT,
      sanitize: sanitizeString,
      transform: addHttpToLink
    },
    validation: genLink()
  },
  {
    field: 'price',
    displayName: 'Hinta',
    input: {
      isRequired: true,
      min: 0,
      inputType: inputTypes.NUMBER,
      unit: '€',
      sanitize: sanitizeNumber
    },
    validation: genRequiredMinField(0, 'Hinta', 'Hinnan', ' €')
  },
  {
    field: 'loanCharge',
    displayName: 'Rahoitusvastike',
    input: {
      isRequired: false,
      min: 0,
      inputType: inputTypes.NUMBER,
      unit: '€/kk',
      sanitize: sanitizeNumber
    },
    validation: genMinField(0, 'Rahoitusvastikkeen', ' €/kk')
  },
  {
    field: 'maintenanceCharge',
    displayName: 'Hoitovastike',
    input: {
      isRequired: false,
      min: 0,
      inputType: inputTypes.NUMBER,
      unit: '€/kk',
      sanitize: sanitizeNumber
    },
    validation: genMinField(0, 'Hoitovastikkeen', ' €/kk')
  },
  {
    field: 'includedInPrice',
    displayName: 'Kuuluu hintaan',
    input: {
      isRequired: false,
      maxLength: 255,
      inputType: inputTypes.TEXTAREA,
      sanitize: sanitizeString
    }
  },
  {
    field: 'otherExpenses',
    displayName: 'Muut kustannukset',
    input: {
      isRequired: false,
      maxLength: 512,
      inputType: inputTypes.TEXTAREA,
      sanitize: sanitizeString
    }
  },
  {
    field: 'parking',
    displayName: 'Pysäköinti',
    input: {
      isRequired: false,
      maxLength: 255,
      inputType: inputTypes.TEXTAREA,
      sanitize: sanitizeString
    }
  },
  {
    field: 'services',
    displayName: 'Palvelut',
    input: {
      isRequired: false,
      maxLength: 255,
      inputType: inputTypes.TEXTAREA,
      sanitize: sanitizeString
    }
  },
  {
    field: 'access',
    displayName: 'Kulkuyhteydet',
    input: {
      isRequired: false,
      maxLength: 1000,
      inputType: inputTypes.TEXTAREA,
      sanitize: sanitizeString
    }
  },
  {
    field: 'metadata',
    displayName: 'Seuraava esittely',
    input: {
      isRequired: false
    }
  }
]

export default forSaleInputFields
