import { inputTypes } from '../constants'
import {
  sanitizeNumber,
  sanitizeBoolean,
  sanitizeString
} from '../sanitizations'
import { createOption } from './helpers'
import {
  baseValidationFields,
  genIsRequiredField,
  genMinField,
  genPickOneField,
  genRequiredMinField,
  genRequiredBooleanField,
  genRequiredMaxLengthField,
  genRequiredMinIntegerField
} from '../validation'
import { MUNICIPALITIES } from '../../state/constants'

const plotInputFields = [
  {
    field: 'images',
    displayName: 'Kuvat kohteesta (kuvan täytyy olla vaakasuunnassa)',
    input: {
      isRequired: true,
      maxImages: 12
    },
    validation: baseValidationFields['images']
  },
  {
    field: 'overview',
    displayName: 'Kohteen kuvaus',
    input: {
      isRequired: true,
      maxLength: 1300,
      sanitize: sanitizeString
    },
    validation: genRequiredMaxLengthField(1300, 'Kohteen kuvaus')
  },
  {
    field: 'type',
    displayName: 'Tyyppi',
    input: {
      isRequired: true,
      inputType: inputTypes.DROPDOWN,
      options: [createOption('Vuokrattava'), createOption('Myytävä')],
      sanitize: sanitizeString
    },
    validation: genPickOneField('Tyyppi')
  },
  {
    field: 'address',
    displayName: 'Osoite',
    input: {
      isRequired: true,
      maxLength: 512,
      inputType: inputTypes.TEXT,
      sanitize: sanitizeString
    },
    validation: genIsRequiredField('Osoite')
  },
  {
    field: 'zipCode',
    displayName: 'Postinumero',
    input: {
      isRequired: true,
      inputType: inputTypes.TEXT,
      sanitize: sanitizeString
    },
    validation: baseValidationFields['zipCode']
  },
  {
    field: 'municipality',
    displayName: 'Kaupunki / kunta',
    input: {
      isRequired: true,
      inputType: inputTypes.DROPDOWN,
      options: MUNICIPALITIES.map(name => createOption(name)),
      sanitize: sanitizeString
    },
    validation: baseValidationFields['municipality']
  },
  {
    field: 'district',
    displayName: 'Kaupunginosa',
    input: {
      isRequired: false,
      maxLength: 255,
      inputType: inputTypes.TEXT,
      sanitize: sanitizeString
    }
  },
  {
    field: 'locationInformation',
    displayName: 'Lisätietoja sijainnista',
    input: {
      isRequired: false,
      inputType: inputTypes.TEXT,
      sanitize: sanitizeString
    }
  },
  {
    field: 'plotAreaM2',
    displayName: 'Tontin pinta-ala',
    input: {
      isRequired: true,
      min: 0,
      inputType: inputTypes.NUMBER,
      unit: 'm2',
      sanitize: sanitizeNumber
    },
    validation: genRequiredMinIntegerField(0, 'Pinta-ala', 'Pinta-alan', ' m2')
  },
  {
    field: 'release',
    displayName: 'Vapautuminen',
    input: {
      isRequired: true,
      maxLength: 255,
      inputType: inputTypes.TEXT,
      sanitize: sanitizeString
    },
    validation: genIsRequiredField('Vapautuminen')
  },
  {
    field: 'priceFreeOfDebt',
    displayName: 'Velaton hinta',
    input: {
      isRequired: true,
      min: 0,
      inputType: inputTypes.NUMBER,
      unit: '€',
      sanitize: sanitizeNumber
    },
    validation: genRequiredMinField(0, 'Hinta', 'Hinnan', ' €')
  },
  {
    field: 'priceToBePaid',
    displayName: 'Myyntihinta',
    input: {
      isRequired: true,
      min: 0,
      inputType: inputTypes.NUMBER,
      unit: '€',
      sanitize: sanitizeNumber
    },
    validation: genRequiredMinField(0, 'Hinta', 'Hinnan', ' €')
  },
  {
    field: 'estateTax',
    displayName: 'Kiinteistövero',
    input: {
      isRequired: true,
      min: 0,
      inputType: inputTypes.NUMBER,
      unit: '€/vuosi',
      sanitize: sanitizeNumber
    },
    validation: genRequiredMinField(0, 'Hinta', 'Hinnan', ' €/vuosi')
  },
  {
    field: 'otherExpenses',
    displayName: 'Muut kustannukset',
    input: {
      isRequired: false,
      maxLength: 512,
      inputType: inputTypes.TEXTAREA,
      sanitize: sanitizeString
    }
  },
  {
    field: 'additionalInformation',
    displayName: 'Muuta kauppaan kuuluvaa',
    input: {
      isRequired: false,
      maxLength: 1000,
      inputType: inputTypes.TEXTAREA,
      sanitize: sanitizeString
    }
  },
  {
    field: 'waterSupplyLine',
    displayName: 'Vesihuollon kuvaus',
    input: {
      isRequired: false,
      maxLength: 255,
      inputType: inputTypes.TEXT,
      sanitize: sanitizeString
    }
  },
  {
    field: 'sewerageLine',
    displayName: 'Viemäri',
    input: {
      isRequired: true,
      maxLength: 255,
      inputType: inputTypes.TEXT,
      sanitize: sanitizeString
    },
    validation: genIsRequiredField('Viemäri')
  },
  {
    field: 'powerLine',
    displayName: 'Sähköliittymä',
    input: {
      isRequired: true,
      inputType: inputTypes.DROPDOWN,
      options: [createOption('Kyllä', true), createOption('Ei', false)],
      sanitize: sanitizeBoolean
    },
    validation: genRequiredBooleanField('Sähköliittymä')
  },
  {
    field: 'linesToBeTransferred',
    displayName: 'Siirtyvät liittymät',
    input: {
      isRequired: false,
      maxLength: 255,
      inputType: inputTypes.TEXT,
      sanitize: sanitizeString
    }
  },
  {
    field: 'plotOwner',
    displayName: 'Tontin omistus',
    input: {
      isRequired: true,
      maxLength: 255,
      inputType: inputTypes.TEXT,
      sanitize: sanitizeString
    },
    validation: genIsRequiredField('Tontin omistus')
  },
  {
    field: 'leaser',
    displayName: 'Maanvuokraaja',
    input: {
      isRequired: false,
      maxLength: 255,
      inputType: inputTypes.TEXT,
      sanitize: sanitizeString
    }
  },
  {
    field: 'plotId',
    displayName: 'Kiinteistötunnus',
    input: {
      isRequired: false,
      maxLength: 255,
      inputType: inputTypes.TEXT,
      sanitize: sanitizeString
    }
  },
  {
    field: 'plotName',
    displayName: 'Tontin nimi',
    input: {
      isRequired: false,
      maxLength: 255,
      inputType: inputTypes.TEXT,
      sanitize: sanitizeString
    }
  },
  {
    field: 'plotType',
    displayName: 'Tontin tyyppi',
    input: {
      isRequired: false,
      maxLength: 255,
      inputType: inputTypes.TEXT,
      sanitize: sanitizeString
    }
  },
  {
    field: 'plotInformation',
    displayName: 'Lisätietoja tontista',
    input: {
      isRequired: true,
      maxLength: 255,
      inputType: inputTypes.TEXTAREA,
      sanitize: sanitizeString
    },
    validation: genIsRequiredField('Lisätietoja tontista')
  },
  {
    field: 'permittedBuildingVolumeM2',
    displayName: 'Rakennusoikeus',
    input: {
      isRequired: true,
      min: 0,
      inputType: inputTypes.NUMBER,
      unit: 'm2',
      sanitize: sanitizeNumber
    },
    validation: genRequiredMinField(
      0,
      'Rakennusoikeus',
      'Rakennusoikeuden',
      ' m2'
    )
  },
  {
    field: 'areaToBuiltAreaRatio',
    displayName: 'Tehokkuusluku',
    input: {
      isRequired: false,
      min: 0,
      inputType: inputTypes.NUMBER,
      sanitize: sanitizeNumber
    },
    validation: genMinField(0, 'Tehokkuusluvun')
  },
  {
    field: 'zoning',
    displayName: 'Kaavoitus',
    input: {
      isRequired: true,
      maxLength: 255,
      inputType: inputTypes.TEXT,
      sanitize: sanitizeString
    },
    validation: genIsRequiredField('Kaavoitus')
  },
  {
    field: 'buildings',
    displayName: 'Rakennukset',
    input: {
      isRequired: false,
      maxLength: 255,
      inputType: inputTypes.TEXT,
      sanitize: sanitizeString
    }
  },
  {
    field: 'servicesAndAccess',
    displayName: 'Palvelut ja liikenneyhteydet',
    input: {
      isRequired: true,
      maxLength: 1000,
      inputType: inputTypes.TEXTAREA,
      sanitize: sanitizeString
    },
    validation: genIsRequiredField('Palvelut ja liikenneyhteydet')
  }
]

export default plotInputFields
